.footer{
    background: #F2F6FE;
    position: relative;
    border-radius: 24px 24px 0px 0px;
    border-top: 1px solid rgb(25 118 210 / 8%);
    .top-header {
        padding: 60px 0px;
        text-align: center;
        position: relative;
        h2{
            font-size: 2.2em;
            font-weight: 700;
        }
    }
    .bottom-footer{
        display: flex;
        justify-content: space-between;
        border-top: 1px solid rgb(25 118 210 / 16%);
        padding: 12px 0px;
        align-items: center;
        .social-media{
            display: flex;
            align-items: center;
            list-style: none;
            margin: 0;
            li{
                margin: 0px 4px;
                a{
                    display: flex;
                    svg{
                        color: #1b3e61;
                        transition: all 0.3s;
                        &:hover{
                            color: #1976d2;
                        }
                    }
                }
            }
        }
        .footer-nav{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
            li{
                margin-left: 15px;
                a{
                    color: #4f4f4f;
                    text-decoration: underline;
                    font-size: 14px; 
                    &:hover{
                        color: #1976d2;
                    }
                }
            }
        }
        .copyright{
            margin: 0;
            font-size: 14px;
            color: #4f4f4f; 
        }
    }
}



