.body-wrapper {
  position: relative;
  margin-top: 65px;
}
.hero-banner {
  text-align: center;
  position: relative;
  padding: 90px 0px;
  .hero-banner-content {
    h1 {
      max-width: 820px;
      margin: 0 auto 20px;
      span {
        background: #f2f6fe;
        padding: 0px 4px;
        border-radius: 16px 16px 16px 0px;
        font-weight: 700;
        color: #1b3e61;
      }
    }
    p {
      font-size: 18px;
      max-width: 520px;
      margin: 0 auto 30px;
      letter-spacing: 0.3px;
    }
  }
}

.features {
  .features-grid {
    background: #f2f6fe;
    position: relative;
    padding: 0px 20px;
    border-radius: 16px;
    transition: all 0.5s;
    overflow: hidden;
    border: 1px solid rgb(25 118 210 / 8%);
    height: 100%;
    img {
      max-width: 84px;
      display: block;
      margin: 30px auto 20px;
    }
    h3 {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 16px;
    }
    p {
      margin-bottom: 30px;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.3px;
      color: #4f4f4f;
    }
  }
}

.why-choose-us {
  background: #f8fafe;
  position: relative;
  ul.tb-delivers-list-ul {
    padding-left: 16px;
    li {
      margin-bottom: 12px;
      line-height: 22px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .why-choose-image {
    img {
      border: 1px solid rgb(25 118 210 / 8%);
      border-radius: 16px;
      box-shadow: 0px 0px 30px rgb(27 62 97 / 9%);
    }
  }
  .why-choose-content {
    h3 {
      font-size: 28px;
      line-height: 1.3;
      font-weight: 700;
      margin-bottom: 20px;
    }
    p {
      color: #4f4f4f;
      font-size: 16px;
      line-height: 24px;
      strong {
        color: #212b36;
      }
    }
    ul {
      padding-left: 0px;
      list-style: none;
      li {
        margin-bottom: 15px;
        line-height: 22px;
        position: relative;
        padding-left: 30px;
        &:last-child {
          margin-bottom: 0;
        }
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          background: url(../../assets/images/check-mark-icon.png);
          width: 20px;
          height: 20px;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

.pricing {
  .feature-comparison {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid rgb(25 118 210 / 16%);
    border-bottom: 1px solid rgb(25 118 210 / 16%);
    border-right: 1px solid rgb(25 118 210 / 16%);
    text-align: center;
    flex-direction: row;
    border-radius: 12px;
    overflow: hidden;
    .feature-header-row {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      background: rgb(25 118 210 / 16%);
      .cell {
        padding: 8px 12px;
        box-sizing: border-box;
        border-left: 1px solid rgb(25 118 210 / 16%);
        border-bottom: 0;
        border-top: 0;
        min-height: 58px;
        color: #1b3e61;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        &.feature-title {
          width: 25%;
          justify-content: flex-start;
          text-align: left;
        }
        &.product-title {
          width: 15%;
        }
      }
    }
    .feature-td-row {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .cell {
        padding: 8px 12px;
        box-sizing: border-box;
        border-left: 1px solid rgb(25 118 210 / 16%);
        border-bottom: 0;
        border-top: 0;
        min-height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        &.feature {
          width: 25%;
          justify-content: flex-start;
          text-align: left;
        }
        &.feature-check {
          width: 15%;
        }
      }
      &:nth-child(odd) {
        background: #f2f6fe;
      }
    }
  }

  .feature-comparison > div > div.cell:nth-child(2) {
    border-left: 3px solid #1976d2;
    border-right: 3px solid #1976d2;
  }
  .feature-comparison > .feature-td-row:last-child div.cell:nth-child(2) {
    border-bottom: 3px solid #1976d2;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  .feature-comparison > .feature-header-row > div.cell:nth-child(2) {
    border-top: 3px solid #1976d2;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .feature-comparison .feature-td-row:nth-child(2) .cell.feature,
  .feature-comparison .feature-td-row:nth-last-child(2) .cell:nth-child(2),
  .feature-comparison .feature-td-row:last-child .cell:nth-child(2),
  .feature-comparison .feature-td-row:nth-child(2) .cell:nth-child(2) {
    font-size: 15px;
    font-weight: 700;
  }
  .feature-comparison .feature-td-row:last-child {
    border-radius: 0px 0px 16px 16px;
  }
  .feature-comparison > div > div.cell:nth-child(3) {
    border-left: 0;
  }
}

.call-to-action {
  .call-action-content {
    background: #f2f6fe;
    display: flex;
    padding: 32px;
    border-radius: 16px;
    color: #212b36;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgb(25 118 210 / 8%);
    p {
      margin: 0;
      font-size: 16px;
      line-height: 28px;
      font-weight: 500;
      padding-right: 20px;
      max-width: 800px;
      font-family: "Plus Jakarta Sans";
    }
    .custom-btn {
      min-width: 236px;
    }
  }
}

.testimonials {
  .testimonial-grid {
    position: relative;
    border: 1px solid rgb(25 118 210 / 16%);
    padding: 20px;
    border-radius: 16px;
    .testimonial-header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      img {
        width: 44px;
        border-radius: 50%;
        min-width: 44px;
        height: 44px;
        margin-right: 15px;
      }
      h3 {
        margin: 0;
        font-size: 18px;
        font-weight: 700;
      }
      span {
        font-size: 14px;
        color: #757f95;
      }
    }
    p {
      margin-bottom: 0;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.3px;
      color: #4f4f4f;
    }
  }
}

.width100 {
  width: 100%;
}

.height100 {
  height: 100%;
}
.my-2 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.user_slider_range_cont {
  min-width: 300px;
  max-width: 400px;
  margin: auto;
}
.selected_user {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
}
