.header {
  padding: 0px;
  background: #fff;
  border-bottom: 1px solid #eee;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  transition: all 0.3s;
  .header-left {
    display: flex;
    align-items: center; 
  }
  .header-right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%; 
  } 
}


.logo img {
  max-width: 170px;
  margin-right: 32px;
  }
    
/* Hamburger Menu */
.hamburger-menu {
  display: none !important;
}

/* Drawer Styles */
.drawer-content {
  width: 260px;
  padding: 20px;
}

.drawer-nav-links {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;
}

.close-button {
  align-self: flex-end;
}
.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-right {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  a {
    color: #1b3e61;
    font-weight: 500;
    text-decoration: none;
    &:hover{
      color: #1976d2;
    }
}
}
nav.nav-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .nav-links-left{
    a{
      border-bottom: 4px solid #0000;
      color: #1b3e61;
      display: inline-block;
      font-weight: 500;
      margin: 0 12px;
      padding: 20px 2px;
      text-decoration: none;
      &:hover { 
        border-color: #1976d2;
        color: #1976d2;
      }
    }
  }
  
  .custom-btn{
    padding: 10px 16px;
    min-width: 96px;
    margin-left: 24px;
  }
}

.drawer-nav-links a {
  color: #1b3e61;
    font-weight: 500;
    text-decoration: none;
}

/* Responsive Design */
@media (max-width: 767px) {
  .nav-links {
    display: none !important; /* Hide nav-links on mobile screens */
  }
  .hamburger-menu {
    display: block !important; /* Show hamburger icon on mobile screens */
  }
  .header {
    padding: 9px 0px; 
}
}
