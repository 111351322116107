@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  margin: 0;
  padding: 0;
  font-family: "Plus Jakarta Sans";
  color: #212b36;
  line-height: 1.3;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  letter-spacing: 0.5px;
  font-family: "Plus Jakarta Sans", sans-serif;
}
p {
  margin-top: 0;
}
img {
  max-width: 100%;
  display: block;
}
a {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.MuiContainer-root {
  max-width: 1260px !important;
}
.custom-btn {
  background: #1976d2;
  border: 1px solid #036cd5;
  color: #fff;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  border-radius: 6px;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  letter-spacing: 0.5px;
  transition: all 0.5s;
  min-width: 160px;
  text-align: center;
  justify-content: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
    display: flex;
    align-items: center;
    /* &:after {
            content: '\00bb';
            position: absolute;
            opacity: 0;
            top: -3px;
            right: -20px;
            transition: 0.5s;
            font-size: 20px;
            line-height: 1;
        } */
    svg {
      margin-left: 4px;
      height: 20px;
      width: 20px;
    }
  }
  &:hover {
    background: #1b3e61;
    border-color: #1b3e61;
    /* span{
            padding-right: 25px;
            &:after{
                opacity: 1;
                right: 0; 
            }
        } */
  }
}

.custom-btn-outline {
  background: transparent;
  border: 1px solid #036cd5;
  color: #036cd5;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  border-radius: 6px;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  letter-spacing: 0.5px;
  transition: all 0.3s;
  min-width: 160px;
  text-align: center;
  justify-content: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
    display: flex;
    align-items: center;
    /* &:after {
            content: '\00bb';
            position: absolute;
            opacity: 0;
            top: -3px;
            right: -20px;
            transition: 0.5s;
            font-size: 20px;
            line-height: 1;
        } */
    svg {
      margin-left: 4px;
    }
  }
  &:hover {
    background: #1b3e61;
    border-color: #1b3e61;
    color: #fff;
    /* span{
            padding-right: 25px;
            &:after{
                opacity: 1;
                right: 0; 
            }
        } */
  }
}

.seeDemo-popup {
  .left-dialog-grid {
    position: relative;
    &:before {
      content: "";
      background: #f2f6fe;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }
  .MuiDialog-paper {
    max-width: 860px;
    width: 100%;
    margin: 0 30px;
    .left-info-panel-dialog {
      padding: 32px;
      position: relative;
      .lead-text {
        font-weight: 500;
        font-size: 15px;
      }
      ul {
        padding-left: 0px;
        list-style-type: none;
        li {
          margin-bottom: 16px;
          font-size: 15px;
          position: relative;
          padding-left: 28px;
          &:before {
            content: "";
            background-image: url(../assets/svg/check-icon.svg);
            position: absolute;
            left: 0;
            top: 2px;
            width: 20px;
            height: 20px;
            background-size: contain;
          }
        }
      }
    }
    .seeDemo-form {
      h6 {
        font-weight: 700;
        line-height: 1.4;
        font-size: 20px;
      }
      .custom-btn-outline {
        min-width: 110px;
      }
      .custom-btn {
        min-width: 110px;
      }
    }
  }
}

.signup-popup {
  .MuiDialog-paper {
    border-radius: 12px;
    .MuiGrid2-container {
      height: 100%;
    }
  }
  .left-dialog-grid {
    position: relative;
    &:before {
      content: "";
      background: #f2f6fe;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }

  .steps_center-content {
    height: 400px;
        overflow-y: auto;
        padding-bottom: 8px;
        margin-bottom: 8px;
}
  .left-info-panel-dialog {
    padding: 32px;
    position: relative;
    .lead-text {
      font-weight: 500;
      font-size: 15px;
      color: #4f4f4f;
      line-height: 1.4;
    }
    ul {
      padding-left: 0px;
      list-style-type: none;
      li {
        margin-bottom: 16px;
        font-size: 14px;
        position: relative;
        padding-left: 28px;
        line-height: 22px;
        &:before {
          content: "";
          background-image: url(../assets/svg/check-icon.svg);
          position: absolute;
          left: 0;
          top: 2px;
          width: 20px;
          height: 20px;
          background-size: contain;
        }
      }
    }
  }
}

.MuiDialogContent-root {
  .lead-text {
    font-size: 15px;
    color: #4f4f4f;
    line-height: 1.5;
  }
}

.steps-column {
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  /* max-height: calc(100% - 64px); */
  margin: 0;
  width: 100%;
  max-width: 100%;
  /* height: 100%; */
  max-height: none;

  max-width: 660px;
  margin: 0 auto;
  .step-cols-content {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    padding: 0px;
    padding-top: 30px;
    width: 100%;
  }
}
.mt-4{
  margin-top: 16px;
}
.form-content {
  /* height: calc(100vh - 240px); */
  overflow-y: auto;
  padding: 0px 30px;
  margin-bottom: 16px;
}
.close-popup {
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  z-index: 9;
}
.MuiStepper-root {
  margin-bottom: 30px;
  span.MuiStepLabel-root {
    display: block;
    text-align: center;
    .MuiStepLabel-iconContainer {
      justify-content: center;
    }
  }
}
.MuiStepLabel-label.Mui-active {
  color: rgb(25 118 210);
  font-weight: 600 !important;
}

.steps_center-content::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.steps_center-content::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

.steps_center-content::-webkit-scrollbar-thumb {
  background-color: rgb(21 93 143 / 16%); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.feature-comparison .feature-close {
  fill: #ff6262;
  width: 20px;
}
.domain-static {
  background: #f2f6fe;
  padding: 8px 10px;
  border-radius: 4px;
  p {
    font-weight: 500;
    font-size: 14px;
  }
}
.cell.feature-check svg {
  color: #785dc8;
  width: 20px;
}

label.radio-plan input[type="radio"] {
  display: none;
}
label.radio-plan {
  cursor: pointer;
  position: relative;
}
label.radio-plan .card-content-wrapper {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #eee;
  padding: 10px;
  display: grid;
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
  transition: 200ms linear;
  min-height: 96px;
  border-radius: 8px;
  position: relative;
}
label.radio-plan .check-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  border: solid 2px #e3e3e3;
  border-radius: 50%;
  transition: 200ms linear;
  position: absolute;
  right: 12px;
  top: 12px;
}
label.radio-plan .check-icon:before {
  content: "";
  position: absolute;
  inset: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center center;
  transform: scale(1.6);
  transition: 200ms linear;
  opacity: 0;
}
label.radio-plan input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
label.radio-plan input[type="radio"]:checked + .card-content-wrapper {
  border-color: #1976d2;
  background: rgb(25 118 210 / 8%);
}
label.radio-plan
  input[type="radio"]:checked
  + .card-content-wrapper
  .check-icon {
  background: #1976d2;
  border-color: #1976d2;
  transform: scale(1.2);
}
label.radio-plan
  input[type="radio"]:checked
  + .card-content-wrapper
  .check-icon:before {
  transform: scale(1);
  opacity: 1;
}
label.radio-plan input[type="radio"]:focus + .card-content-wrapper .check-icon {
  box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
  border-color: #3056d5;
}
label.radio-plan .card-content img {
  margin-bottom: 10px;
}
label.radio-plan .card-content h4 {
  font-size: 16px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #7a7a7a;
  margin-bottom: 8px;
  text-transform: uppercase;
  font-weight: 500;
}
label.radio-plan .card-content h5 {
  font-size: 22px;
  line-height: 1.4;
  text-align: left;
  color: #212b36;
  font-family: "Plus Jakarta Sans", sans-serif;
  margin-bottom: 6px;
}
label.radio-plan .card-content h5 span {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.8;
}
p.plan-badge {
  background: rgb(17 181 12 / 8%);
  border-radius: 4px;
  padding: 2px 5px;
  font-weight: 500;
  font-size: 13px;
  display: inline-block;
  margin: 0;
  color: #068d02;
}
.blog-content p {
  color: #4f4f4f;
  font-size: 16px;
  line-height: 26px;
}
label.radio-plan
  input[type="radio"]:checked
  + .card-content-wrapper
  .card-content
  h4 {
  color: #1976d2;
}
.web-text-hide {
  display: none;
}
.seeDemo-content-wrapper {
  display: flex;
  align-items: center;
}
/* Default Header Styling */
.activeLink {
  color: rgb(25, 118, 210) !important;
  border-color: rgb(25, 118, 210) !important;
}

.policy-content p{
  color: #4f4f4f;
    font-size: 16px;
    line-height: 26px;
}
.policy-content h4{
  font-size: 22px;
  line-height: 1.2;
  font-weight: 700;
}
.policy-content ul{
  padding-left: 20px;
}
.policy-content ul li strong, .policy-content strong {
  color: #000;
}
.policy-content a{ 
  color: #1976d2;
}
.thankyou-popup {
  position: relative;
  padding: 40px;
}
.thankyou-popup .MuiDialog-paper{
  max-width: 560px;
  border-radius: 12px;
}
.thankyou-check-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.thankyou-popup ul {
  list-style: none;
  padding: 0;
}
.thankyou-popup ul li {
  margin-bottom: 8px;
  font-size: 15px; 
  color: #4f4f4f;
}
.thankyou-popup ul li strong{
  color: #000;
}
.thankyou-popup p {
  font-size: 15px;
  color: #4f4f4f;
}
.thankyou-popup p a{
  color: #1976d2;
}
.thankyou-popup ul li a{
  color: #4f4f4f;
}