@media only screen and (max-width: 1199px) {
    
}

@media only screen and (max-width: 992px) {
    .pricing .feature-comparison .feature-header-row .cell.feature-title {
        width: 20% !important; 
    } 
    .pricing .feature-comparison .feature-header-row .cell.product-title {
        width: 16% !important;
    }
    .pricing .feature-comparison .feature-td-row .cell.feature {
        width: 20% !important; 
    }
    .pricing .feature-comparison .feature-td-row .cell.feature-check {
        width: 16% !important;
    }
    .hero-banner .hero-banner-content h1 { 
        font-size: 3rem;
        max-width: 670px;
    } 
    .feature-comparison .feature-td-row:nth-child(2) .cell.feature,
    .feature-comparison .feature-td-row:nth-last-child(2) .cell:nth-child(2),
    .feature-comparison .feature-td-row:last-child .cell:nth-child(2),
    .feature-comparison .feature-td-row:nth-child(2) .cell:nth-child(2) {
        font-size: 14px; 
    }
    .form-content { 
        padding: 0px 40px;
    }
    .header .header-right ul li {
        margin-left: 12px;
    } 
    .signup-popup .left-dialog-grid {
        position: relative;
        height: 660px !important;
        overflow-y: auto;
        background: #f2f6fe;
    }
}


@media only screen and (max-width: 767px) {
    .hero-banner .hero-banner-content h1 {
        font-size: 30px; 
        max-width: 520px;
    } 
    .hero-banner .hero-banner-content p{
        font-size: 16px;
    }
    .why-choose-us .why-choose-content   h3 {
        font-size: 26px; 
    }
    .steps_center-content {
        height: auto !important; 
    } 
    .why-choose-us .why-choose-content h3 {
        font-size: 24px; 
    } 
    .call-to-action .call-action-content { 
        flex-direction: column;
        padding: 20px;
    } 
    .call-to-action .call-action-content p{
        padding-right: 0 !important;
        text-align: center;
        margin-bottom: 20px !important;
    }
    .pricing .feature-comparison{
        overflow-x: auto !important;
    }
    h2{
        font-size: 30px !important;
    }
    .hero-banner { 
        padding: 40px 0px 40px;
    }
    .footer .bottom-footer { 
        flex-wrap: wrap;
    } 
    .footer .bottom-footer .copyright { 
        width: 100%;
        text-align: center;
        order: 3;
    } 
    .footer .bottom-footer .social-media,
    .footer .bottom-footer .footer-nav { 
        padding: 0;
        justify-content: center;
        width: 100%;
        margin-bottom: 12px;
    }  
    .pricing .feature-comparison { 
        overflow-x: auto;
    } 
    .pricing .feature-comparison .feature-header-row,
    .pricing .feature-comparison .feature-td-row { 
        min-width: 720px;
    } 
    .signup-popup .left-dialog-grid,
    .seeDemo-popup .left-dialog-grid { 
        display: none;
    } 
    .form-content {
        padding: 0px 20px;
    }
    .steps-column .step-cols-content { 
        padding-top: 20px;
    } 
    .web-text-hide{
        display: block;
    }
    .close-popup { 
        padding: 0 !important;
    }
    .card-content > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    label.radio-plan .card-content-wrapper { 
        min-height: 1px; 
    }
    .seeDemo-popup .MuiDialog-paper { 
        margin: 0 20px;
        height: auto;
    } 
    .signup-popup .MuiDialog-paper .MuiGrid2-container { 
        justify-content: center;
    } 
}


@media only screen and (max-width: 580px) {
    .top-header h2 {
        max-width: 300px;
        margin: 0 auto 20px;
    }
}